import React from 'react'
import "../whyChoose/whychoose.css"

// icon
import { IoHome } from "react-icons/io5";



const InteriorSolution = () => {
  return (
    <div className='interiorSolution__ py-10'>
        <div className='container mx-auto'>
            <div className='interiorSolution__title text-center mb-10'> 
                <span>From Concept to <span className='whyChoose__titleRed'> Execution</span></span>
            </div>
            <div className='interiorSolution__container grid sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4  my-20 gap-y-20'>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   

                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Modular Kitchen.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Modular Kitchen</span>
                    </div>

                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Sliding warrobe.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Hinged & Sliding Wardrobes</span>
                    </div>
                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/False ceiling-1.webp' alt='img' />
                    </div>
                    <div className='mt-4 text__color text-center'>
                        <span>False Ceiling</span>
                    </div>
                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/bunk-bed-1.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Bunk Beds</span>
                    </div>
                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Bathroom cabinets-1.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Bathroom Cabinets</span>
                    </div>
                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Work space.webp' alt='img' />
                    </div>
                    <div className='mt-4 text__color text-center'>
                        <span>Work Space</span>
                    </div>  
                </div>

                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                  

                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Furniture design ND customize.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span >Furniture Customization</span>
                    </div>
                </div>

                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Flooring.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Flooring Services</span>
                    </div>
                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   

                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Decors-1.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Decor and Styling</span>
                    </div>
                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Colour consultations-1.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Colour Consultation</span>
                    </div>
                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Lighting fixtures-1.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Lighting Fixtures</span>
                    </div>
                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   

                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Wallpapers.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Wallpapers & Coverings</span>
                    </div>
                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Wall paintings.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Wall Paintings</span>
                    </div>

                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   <div className='interiorSolutionContent__img'>
                        <img src='/images/Home automation.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Home Automation</span>
                    </div>

                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                   <div className='interiorSolutionContent__img'>
                        <img src='/images/Renovation.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Renovation and Remodelling</span>
                    </div>

                </div>
                <div className='interiorSolution__content flex flex-col justify-center items-center'>
                    <div className='interiorSolutionContent__img'>
                        <img src='/images/Electrical and plumbings-1.webp' alt='img' />
                    </div>
                    <div className='mt-4 text-center text__color'>
                        <span>Electrical and plumbings</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default InteriorSolution