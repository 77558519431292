import React from 'react'
import "./whychoose.css"



const Whychoose = () => {
  return (
    <div>
        <div className='container mx-auto my-20'>
            <div className='whyChoose__title text-center mb-10'>
                <span>Why Choose <span className='whyChoose__titleRed'>Project X</span></span>
            </div>
            <div className='whyChoose__container flex items-center justify-center flex-wrap gap-x-20 sm:gap-x-48 gap-y-10'>
                <div className='whyChoose__content flex items-center justify-center flex-col'>
                    <div className='whyChoose-content__img grid place-content-center'>
                        <img src='/images/Emi 2.webp' alt='img' />
                    </div>
                    <div className='text-center'>
                        45 days or your <br/> rent is on us
                    </div>
                </div>
                <div className='whyChoose__content flex items-center justify-center flex-col'>
                    <div className='whyChoose-content__img grid place-content-center -mt-4'>
                        <img src='/images/warranty copy.webp' alt='img' />
                    </div>
                    <div className='text-center'> 
                      No Hidden Cost
                    </div>
                </div>
                <div className='whyChoose__content flex items-center justify-center flex-col'>
                    <div className='whyChoose-content__imgs'>
                        <img src='/images/cost cutting.webp' alt='img' />
                    </div>
                    <div className='text-center'>  Easy EMI</div>
                </div>
                <div className='whyChoose__content flex items-center justify-center flex-col'>
                    <div className='whyChoose-content__img grid place-content-center'>
                        <img src='/images/45days-1.webp' alt='img' />
                    </div>
                    <div className='text-center'>Enjoy our <br/> 10-year warranty</div>

                </div>
            </div>

            {/* Banner Design */}
            <div className='banner__image'>
               <div className='banner__Img my-20'>
                   {/* <img src='/images/website-banner.webp' alt='' /> */}
               </div>
            </div>
           
           
        </div>
         
    </div>
  )
}

export default Whychoose