import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const Partners = () => {
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          speed: 3500,
          autoplaySpeed: 1000,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          speed: 3500,
          autoplaySpeed: 1000,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          speed: 3500,
          autoplaySpeed: 1000,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          speed: 3500,
          autoplaySpeed: 1000,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          speed: 3500,
          autoplaySpeed: 1000,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          speed: 6500,
          autoplaySpeed: 1000,
        },
      },
    ],
  };

  return (
    <div className="my-20 partners">
      <div className="interiorSolution__title text-center mb-10">
        <span>
        Preferred Brands in  <span className="whyChoose__titleRed"> Our Toolbox</span>
        </span>
      </div>
      <Slider {...settings}>
       <div>
          <div className="interiorSolution__img">
            <img src="/images/part10.png" alt="" />
          </div>
        </div> 
        <div>
          <div className="interiorSolution__img">
            <img src="/images/part2.png" alt="" />
          </div>
        </div>
        <div>
          <div className="interiorSolution__img">
            <img src="/images/part3.png" alt="" />
          </div>
        </div>
        <div>
          <div className="interiorSolution__img">
            <img src="/images/part4.png" alt="" />
          </div>
        </div>
        <div>
          <div className="interiorSolution__img">
            <img src="/images/part5.png" alt="" />
          </div>
        </div>
        <div>
          <div className="interiorSolution__img">
            <img src="/images/part6.png" alt="" />
          </div>
        </div>
        <div>
          <div className="interiorSolution__img">
            <img src="/images/part7.png" alt="" />
          </div>
        </div>
        <div>
          <div className="interiorSolution__img">
            <img src="/images/part8.png" alt="" />
          </div>
        </div>
        <div>
          <div className="interiorSolution__img">
            <img src="/images/part9.png" alt="" />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Partners;
