import Homepage from "./components/homePage/homepage";
import { useEffect, useState } from "react";
import Loader from "./components/loader/loader";



function App() {

  const [ loader, setLoader ] = useState(true)

  useEffect( () => {
    setTimeout( () => {
      setLoader(false)
    }, 2000)
  })

  return (
    <div className="App">

     { loader ? ( <Loader /> ) : ( <Homepage /> ) }
     
   </div>
  );
}

export default App;
