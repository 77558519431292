import React, { useEffect, useState } from 'react'
import "../whyChoose/whychoose.css"
import Popup from '../../popup/popup';

const Work = () => {

    const [isFormVisible, setFormVisible] = useState(null);


     const toggleForm = () => {
          setFormVisible(!isFormVisible);
      };
    
    
  return (
    <div>
        <div className='container mx-auto grid place-content-center my-20'>
           <div className='interiorSolution__title text-center mb-10'>
                 <span>How it <span className='whyChoose__titleRed'> Work</span></span>
            </div>

                <ul className='work__container lg:flex place-content-center gap-y-20'>
                    <li className='work__content lg:flex items-center'>
                        <div className='flex flex items-center justify-center flex-col'>
                          <div className='work-content__img mt-5'>
                              <img src='/images/work2.png' alt='img' />

                          </div>
                          <p className='text-center mt-3'> Schedule a <br></br> meeting session </p>

                        </div>
                        <div className='line__ lg:-mt-5'></div>


                    </li>
                    <li className='work__content lg:flex items-center'>
                    <div className='flex items-center justify-center flex-col'>

                        <div className='work-content__img lg:mt-5 '>
                            <img src='/images/work3.png' alt='img' />
                        </div>
                        <p className='text-center mt-3'>Visualize your <br></br> home in 3D Designs</p>
                     </div>
                     <div className='line__ lg:-mt-5'></div>

                    </li>
                   
                    <li className='work__content flex items-center'>
                    <div className='flex flex items-center justify-center flex-col'>

                        <div className='work-content__img lg:mt-5'>
                            <img src='/images/work1.png' alt='img' />
                        </div>
                        <p className='text-center mt-3'> Book <span className='project-x__red'> Project X <br></br> Interior Studio </span></p>
                    </div>
                    <div className='line__ lg:-mt-5'></div>

                    </li>
                   
                    <li className='work__content flex items-center'>
                    <div className='flex flex items-center justify-center flex-col'>

                        <div className='work-content__img lg:mt-3'>
                            <img src='/images/work4.png' alt='img' />
                        </div>
                        <p className='text-center mt-3'> Get your key<br></br> in 45 Days </p>
                    </div>

                    </li>
                   
                   
                </ul>
            

               <div className='wrk__btn w-full grid place-content-center mt-14'>
                <button onClick={toggleForm}>Take the first step</button>
               </div>

               <Popup  isOpen={isFormVisible} onToggleForm={toggleForm} />


         {isFormVisible === false && (
             <div id="icon" onClick={toggleForm}>
               <div className="chant-icon">
                 <i className="fa-solid fa-comments"></i>
               </div>
             </div>
         )}
         
            </div>

    </div>
  )
}

export default Work