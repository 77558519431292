import React, { useState, useEffect } from "react";
import "./popup.css";
import Loader from "../loader/loader";
import emailjs from "@emailjs/browser";
import Axios from "axios";

const Popup = ({ isOpen, onToggleForm }) => {
  const [formData, setFormData] = useState({
    userName: "",
    userNumber: "",
    userEmail: "",
  });

  const [formError, setFormError] = useState({
    userName: "",
    userNumber: "",
    userEmail: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const inputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const validation = () => {
    let formValid = true;
    const newError = {};

    if (!formData.userName) {
      formValid = false;
      newError.userName = "Name is required";
    }

    if (!formData.userNumber) {
      formValid = false;
      newError.userNumber = "Mobile number is required";
    }

    if (!formData.userEmail) {
      formValid = false;
      newError.userEmail = "Email is required";
    }

    setFormError(newError);
    return formValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validation()) {
      try {
        const serviceId = "service_t86i9eu";
        const templateId = "template_9pki5h5";
        const publicKey = "c-ikd0cloYvNI47cT";

        const data = {
          service_id: serviceId,
          template_id: templateId,
          user_id: publicKey,

          template_params: {
            from_name: formData.userName,
            from_number: formData.userNumber,
            from_email: formData.userEmail,
          },
        };

        const res = await Axios.post(
          "https://api.emailjs.com/api/v1.0/email/send",
          data
        );
        console.log(res.data);
        setFormData({
          userName: "",
          userNumber: "",
          userEmail: "",
        });
        setShowSuccessPopup(true);
      } catch (error) {
        console.error("Error sending email:", error);
      }

 
    }
  };

  const handleClose = () => {
    setFormData({
      userName: "",
      userNumber: "",
      userEmail: "",
    });

    setFormError({
      userName: "",
      userNumber: "",
      userEmail: "",
    });

    onToggleForm();
  };

  useEffect(() => {
    if (showSuccessPopup) {
      const timeout = setTimeout(() => {
        onToggleForm();

        setShowSuccessPopup(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [showSuccessPopup]);

  return (
    <div>
      {isOpen && (
        <div className="popup__container">
          <div className="popup">
            <span id="closeBtn" onClick={handleClose}>
              <i className="fa-solid fa-xmark"></i>
            </span>

            <form id="popupForm" onSubmit={handleSubmit}>
              <div className="user-form">
                <i className="fa-solid fa-user"></i>
                <input
                  type="text"
                  placeholder="Enter your name"
                  id="username"
                  maxLength="15"
                  name="userName"
                  value={formData.userName}
                  onChange={inputChange}
                />
              </div>
              {formError.userName && (
                <div className="error-msg">{formError.userName}</div>
              )}

              <div className="user-form">
                <i className="fa-solid fa-phone"></i>
                <input
                  type="text"
                  placeholder="Enter your Mobile number"
                  id="phonenumber"
                  maxLength="10"
                  name="userNumber"
                  value={formData.userNumber}
                  onChange={inputChange}
                />
              </div>
              {formError.userNumber && (
                <div className="error-msg">{formError.userNumber}</div>
              )}

              <div className="user-form">
                <i className="fa-solid fa-envelope"></i>
                <input
                  type="email"
                  placeholder="Enter your Email id"
                  id="email"
                  name="userEmail"
                  value={formData.userEmail}
                  onChange={inputChange}
                />
              </div>
              {formError.userEmail && (
                <div className="error-msg">{formError.userEmail}</div>
              )}

              <button type="submit">Submit</button>
            </form>

          </div>
        </div>
      )}

{showSuccessPopup && (
              <div className="success-popup grid place-content-center">
                <div>
                  <p>Form submitted successfully!</p>
                </div>
              </div>
            )}

    </div>
  );
};

export default Popup;
