import React from 'react'
import Whychoose from './whyChoose/whychoose'
import InteriorSolution from './interiorSolution/interiorSolution'
import Partners from './partners/partners'
import Footer from './footer/footer'
import Work from './work/work'
import HomeMain from './main/homeMain'
import OurprojectNew from './ourProject/ourprojectNew'
import Ourproject from './ourProject/ourproject'
import IndustrialInterior from './industrialInterior/industrialInterior'
import Contemporary from './contemporary/contemporary'
// import WhatsAppWidget from './watti'

const Homepage = () => {
  return (
    <div>
        <HomeMain />
        <Whychoose />
        <InteriorSolution />
        <OurprojectNew />
        <IndustrialInterior />
        <Contemporary />
        <Partners />
        {/* <WhatsAppWidget /> */}
        {/* <Ourproject /> */}
        <Work /> 
        <Footer />
    </div>  
  )
}

export default Homepage