import React from "react";
import "../whyChoose/whychoose.css";
import { FaLocationDot } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { PiPhoneCallFill } from "react-icons/pi";
import { BsWhatsapp } from "react-icons/bs";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="container mx-auto mt-32">
      <div className="lg:flex flex-wrap  px-5 md:pl-0">
        <div className="footer__about  lg:w-1/3 xl:pr-20">
          <div className="footer-about__title mb-3">
            <span>About Us</span>
          </div>
          <div className="footer-about__para">
            <span>
              At <span className="about-red">Project X interior studio</span>,
              we believe that every space has its own unique story waiting to be
              told. Our team of seasoned designers is dedicated to unraveling
              that narrative and crafting environments that resonate with your
              style, personality, and vision. We understand that your home or
              commercial space is more than just a physical structure – it's an
              extension of who you are.
            </span>
          </div>
        </div>
        <div className="flex flex-wrap  lg:w-2/3 mt-10 lg:mt-0  ">
          <div className="footer__navigator w-full sm:w-1/2 lg:ml-20">
            <div className="footer-navigator__title mb-3">
              <span>Navigations</span>
            </div>

            <div className="footer-navigator__container flex items-center gap-x-10 md:gap-x-20 lg:gap-x-10 xl:gap-20">
              <div className="footer-navigator__links">
                <p>
                  <a href="">Home</a>
                </p>
                <p className="my-2">
                  <a href="">Service</a>
                </p>
                <p>
                  <a href="">Work</a>
                </p>
                <p className="my-2">
                  <a href="">Process</a>
                </p>
                <p>
                  <a href="">About Us</a>
                </p>
              </div>
              <div className="footer-navigator__links">
                <p>
                  <a href="">Press</a>
                </p>
                <p className="my-2">
                  <a href="">Blog</a>
                </p>
                <p>
                  <a href="">Contact</a>
                </p>
                <p className="my-2">
                  <a href="">Support</a>
                </p>
                <p>
                  <a href="">Privacy</a>
                </p>
              </div>
              <div className="footer-navigator__links">
                <p>
                  <a href="">Privacy</a>
                </p>
                <p className="my-2">
                  <a href="">FAQ</a>
                </p>
                <p>
                  <a href="">Careers</a>
                </p>
                <p className="my-2">
                  <a href="">Process</a>
                </p>
                <p>
                  <a href="">About Us</a>
                </p>
              </div>
            </div>
          </div>

          <div className="footer__form mt-10 sm:mt-0 sm:w-1/2 lg:w-1/3">
            <div className="footer-about__title	 mb-3">
              <span>Get in Touch</span>
            </div>
            <div className="footer-about__para">
              <span>
              Elevate your space with our interior studio. WhatsApp us now for stunning design transformations.              </span>
            </div>
            <button>
              <BsWhatsapp className="mr-2" /> Connect
            </button>
          </div>
        </div>
      </div>

      <div className="contact__socialIcon mt-10 grid sm:grid-cols-2 lg:grid-cols-3 place-content-center gap-10 mt-20">
        <a
          href="https://www.google.com/maps/place/ProjectX+Interior+Studio/@13.1299922,80.2113491,17z/data=!3m1!4b1!4m6!3m5!1s0x3a52650047264487:0xf0c0c3e146c530e9!8m2!3d13.129987!4d80.213924!16s%2Fg%2F11vrlh277q?entry=ttu"
          target="blank"
        >
          {" "}
          <div className="footer__location h-60 text-center flex justify-center flex-col items-center">
            <div className="grid place-content-center footer__locoIcon">
              <FaLocationDot />
            </div>
            <span>Our Address</span>
            <div>
              No: 7, Perambur Redhills Road,<br></br> Retteri flyover, Kolathur,{" "}
              <br></br>Chennai-600099
            </div>
          </div>{" "}
        </a>

        <div className="footer__location h-60 text-center flex justify-center flex-col items-center px-10 lg:px-10">
          <div className="grid place-content-center footer__locoIcon">
            <FaEnvelope />
          </div>
          <span>Email Us</span>
          <div className="text-center">
            <a href="https://mail.google.com/" target="blank">
              {" "}
              <spn>projectxinteriorstudio@gmail.com</spn>{" "}
            </a>
          </div>
        </div>

        <div className="footer__location  h-60 text-center flex justify-center flex-col items-center">
          <div className="grid place-content-center footer__locoIcon">
            <PiPhoneCallFill />
          </div>
          <span>Call Us</span>
          <a href="tel:8124446669">
            {" "}
            <div>+91 8124446669</div>{" "}
          </a>
        </div>
      </div>

      <div className="copyRights text-center mt-10 py-10">
        <span>Copyright © {year} projectxinterior.com. All Rights Reserved </span>
        <span className="block mt-2">
          Developed by <span className="deleloped">Brandlet</span>{" "}
        </span>{" "}
      </div>

      <div className=" px-5 md:px-0 text-justify footer-about__para">
        <span>
        At<span className="about-red"> Project X Interior Studio</span>, discover tailored home designs merging
          functionality with aesthetics. Our specialized designers focus on
          crafting personalized interiors, from elegant living rooms to
          space-efficient designs, reflecting your lifestyle. Whether you seek
          sophisticated decor or clutter-free spaces, find the perfect match for
          your needs and style. Enjoy up to a 10-year warranty on all products,
          coupled with dedicated support and maintenance services. Explore a
          myriad of inspiring interior designs or request a free estimate - it's
          all here, your ultimate destination for complete home interiors
        </span>
      </div>

      <div className="footer-about__para flex flex-wrap gap-2 mt-10 py-5 footer-top__ pl-5 md:pl-0">
        <div className="flex">
          <span className="footer__content-name">Modular Kitchen Designs</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Home Interior Designs</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Wardrobe Designs</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Living room Designs</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Bathroom Designs</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Space Saving Furniture</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Home Office Designs</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Pooja Room Designs</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Foyer Interior Design</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Kids Bedroom Design</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">Interior Lighting Design</span>{" "}
          <span className="mx-2 block">|</span>
        </div>
      </div>

      <div className="footer-about__para flex flex-wrap gap-2 mt-5 py-5 footer-top__ pl-5 md:pl-0">
        <div className="flex">
          <span className="footer__content-name">
            Modular Kitchen Design in chennai
          </span>
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">
            Home Interior Design in chennai
          </span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">
            Wardrobe Design in chennai
          </span>
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">
            Living room Design in chennai
          </span>
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">
            Bathroom Designs in chennai
          </span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">
            Space Saving Furniture in chennai
          </span>
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">
            Home Office Designs in chennai
          </span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">
            Pooja Room Designs in chennai
          </span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">
            Foyer Interior Design in chennai
          </span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex">
          <span className="footer__content-name">
            Kids Bedroom Design in chennai
          </span>{" "}
          <span className="mx-2 block">|</span>
        </div>
        <div className="flex ">
          <span className="footer__content-name">
            Interior Lighting Design in chennai
          </span>
          <span className="mx-2 block">|</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
