import React, { useState, useEffect } from 'react'
import "./homeMain.css"
import { IoCallSharp } from "react-icons/io5";
import Popup from '../../popup/popup';
import Navbar from '../header/header';
import { FaFacebookF, FaInstagram  } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Loader from '../../loader/loader';


const HomeMain = () => {

  const [isFormVisible, setFormVisible] = useState(null);
  const [navOpen, setNavOpen] = useState(false);

  const navbarOpen = () => {
    setNavOpen(true);
  };

  const navbarClose = () => {
    setNavOpen(false);
  };


  useEffect(() => {

    const timer = setTimeout(() => {
      setFormVisible(true);
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  const toggleForm = () => {
      setFormVisible(!isFormVisible);
  };


  const backgroundImageStyle = {
    backgroundImage: 'url("/images/homeImg.webp")',
    width: "100%",
    height:"100vh",
    minHeight:"670px",
    backgroundSize:"cover",
    backgroundPosition:"center / cover"
  };


  return (
   <div className='main-container' style={backgroundImageStyle}>
    <div className='homeMain container mx-auto'>
      <header className='flex items-center py-6 pr-3'>
            
            <nav className='hidden lg:block'>
                <ul className='homeMain-container__nav flex items-center gap-x-10'>
                    <a href='#' ><li className='link'>Home</li></a>
                    <a href='#'><li className='link'>Project</li></a>
                    <a href='#'><li className='link'>Service</li></a>
                    <a href='#'><li className='link'>About</li></a>
                    <a href='#'><li className='link'>Contact Us</li></a>
                </ul>
            </nav>

            <div className='main-container__logoImg lg:ml-36 xl:ml-52 2xl:ml-64 mt-10x'>
                <img src='/images/logo.png' alt='img' />
            </div>

            <div className='homeMain-container__contact ml-auto'>
              <a  href="tel:7871864111" className='flex items-center'> <IoCallSharp className='mr-2'/><span>+91 812 444 6669</span></a>
            </div>

            <button className='md:hidden ml-5 text-white' onClick={navbarOpen} >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
              </svg>
            </button>
      </header>
      <div className='homeMain__container md:flex xl:justify-center sm:mt-10 xl:mt-40 md:pl-10 px-5'>
        <div className='xl:-ml-56'>
           <div className='homeMain__title'>
             <span><span className='block'>Feel</span> <span class="block">the <span class="anime__color">Vibe</span>,</span> <span class="block">Live the <span class="anime__col">Style</span></span></span>   
           </div>
           <div class="play-vid mt-5">
	       		<button id="openPopupButton" class="home-btn" onClick={toggleForm}>Get 3D Design Session <span className='home-btn__free'></span> </button>
	       </div>  
        </div>
        <Popup  isOpen={isFormVisible} onToggleForm={toggleForm} />


        {isFormVisible === false && (
            <div id="icon" onClick={toggleForm}>
              <div className="chant-icon">
                <i className="fa-solid fa-comments"></i>
              </div>
            </div>
        )}

	    	<div class="homeMain__para mt-5 px-5 sm:px-0">
	    		<div class="line"></div>
	    		<p>Transform your living space with the <span class="caps">PROJECT X</span> enchantment – where style effortlessly intertwines with substance. Our ingenious approach guarantees your home becomes a masterpiece of timeless beauty and seamless functionality. Step into a design that withstands the sands of time!</p>
	    	</div>
      </div>

      <div className='side__socialIcon'>
        <div className='flex justify-between flex-col items-center w-full h-full py-5'>
          <a href='https://www.facebook.com/projectxinteriorstudio' target='blank'><FaFacebookF className='side-icons__'/></a> 
		      <a href=' https://www.instagram.com/projectxinteriorstudio/' target='blank'><FaInstagram className='side-icons__'/></a> 
		      <a href=' https://twitter.com/ProjectXIn52649' target='blank'><FaXTwitter className='side-icons__' /></a> 
        </div>
      </div>
     </div> 
     <Navbar isOpen={navOpen} close={navbarClose} />


    </div>
  )
}

export default HomeMain