import React from 'react'
import "./header.css"
import { IoClose } from "react-icons/io5";



const Navbar = ( { isOpen, close } ) => {


  return (
    <div className={`navBar p-5 ${ isOpen ? 'open' : "" }`}>
        <div className='navBar-container'>
            <div className='navBar-closeIcon flex w-full' onClick={ close }>
             <IoClose className='ml-auto'/>
            </div>
            <ul className='p-10'>
                <li className='navBar-default'>Home</li>
                <li className='my-3'>Project</li>
                <li>Service</li>
                <li className='my-3'>About</li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
  )
}

export default Navbar