import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';


const Contemporary = () => {
  return (
    <div className="ourProject__container container mx-auto mt-20 lg:px-10 ">
      <div className="interiorSolution__title text-center mb-10">
        <span>
        Contemporary <span className="whyChoose__titleRed">Concepts</span>
        </span>
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src="/images/com3.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/com9.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/com3.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/com4.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/com5.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/com6.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/com7.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/com8.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/com2.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/com1.jpg" alt="slide_image" />
        </SwiperSlide>


        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
};

export default Contemporary;
